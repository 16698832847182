<template>
  <div class="comicDetailBox">
      <img
        v-for="item in micList"
        :key="item"
        v-lazy="item"
        alt=""
      />
  </div>
</template>
<script>
import { comicList } from "@/config/config.json";
export default {
  name: "comicDetail",
  data() {
    return {
      comicId: null,
      micList: [],
    };
  },
  async created() {
    this.comicId = this.$route.query.comicId;
    await comicList.forEach((element) => {
      if (element.id === this.comicId) {
        this.micList = element.comicPath;
      }
    });
  },
  methods: {},
};
</script>
<style scoped>
.comicDetailBox {
  width: 100%;
  display: flex;
  flex-direction: column;
}
img{
  width: 50%;
  margin: 0 auto;
}
</style>
