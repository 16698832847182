<template>
  <div id="app" class="container">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      router
      style="width: 100%; display: flex; flex-direction: row; justify-content: center"
    >
      <el-menu-item
        ><img
          src="https://cdn.keshuoyc.com/ksyc-web/logo.png
"
          alt=""
        /><b>科硕云创（南京）科技有限公司</b></el-menu-item
      >
      <el-menu-item
        v-for="item in tabberBarData"
        :key="item.id"
        :index="item.routerPath"
        style="font-size: 20px; font-weight: bold"
        >{{ item.title }}</el-menu-item
      >
    </el-menu>
    <router-view />

    <el-footer style="padding: 100px">
      <p>科硕云创（南京）科技有限公司</p>
      <p>地址：南京市建邺区福园街133号1215室</p>
      <p @click="jumpRecord()">备案号：苏ICP备2024143606号-1</p>
    </el-footer>
  </div>
</template>
<script>
/* eslint-disable */
import { tabberBarData } from "./config/config.json";
export default {
  data() {
    return {
      activeIndex: "/",
      tabberBarData: [],
    };
  },
  created() {
    this.tabberBarData = tabberBarData;
    this.tabberBarData.forEach((el) => {
      if (
        el.routerPath == this.$route.path ||
        this.$route.path.toString().slice(0, 6) == el.routerPath
      )
        this.activeIndex = el.routerPath;
    });
  },
  methods:{
    jumpRecord(){
      window.open("https://beian.miit.gov.cn/#/Integrated/index");
    }
  }
};
</script>
<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  --big-font-size: 20px;
}
img {
  width: 70px;
  margin: 0 20px;
}
</style>
