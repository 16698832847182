<template>
  <div class="comicListBox">
    <div class="comicOptionContent">
      <el-card v-for="item in comicList" :key="item.id" style="margin:10px;">
        <div
          class="cardBox"
          @click="
            $router.push({ path: '/comic/comicDetail', query: { comicId: item.id } })
          "
        >
          <img
            v-lazy="item.PicPath"
            class="image"
          />
          <div class="bookDetail">
            <p>《{{ item.name }}》</p>
            <div class="booktag">第一章</div>
            <p style="color: #666666">已完结</p>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import { comicList } from "@/config/config.json";
export default {
  data() {
    return {
      comicList: [],
    };
  },
  created() {
    this.comicList = comicList;
  },
};
</script>
<style scoped>
.comicListBox {
  width: 100%;
}
.cardBox {
  display: flex;
}
.cardBox p {
  font-weight: bold;
  font-size: 24px;
}
.booktag {
  border: 1px solid rgb(252, 99, 99);
  color: rgb(252, 99, 99);
  width: 40px;
  font-size: 10px;
  height: 15px;
  line-height: 15px;
  text-align: center;
}
.comicOptionContent {
  font-size: 16px;
  color: #666;
}
.comicOptionTitle,
.comicOptionContent {
  text-align: left;
  width: 1200px;
  margin: auto;
  padding: 15px;
  line-height: 40px;
}
.image {
  width: 240px;
  height: 204px;
  margin-right: 30px;
  border-radius: 10px;
}
</style>
