import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ComicView from '../views/ComicView.vue'
import CustomerView from '../views/CustomerView.vue'
import SelfReviewView from '../views/SelfReviewView.vue'
import UserAgreementView from '../views/UserAgreementView.vue'
import comicList from '@/views/commic/comicList.vue'
import comicDetail from '@/views/commic/comicDetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/userAgreement',
    name: 'userAgreement',
    component: UserAgreementView
  },
  {
    path: '/selfReview',
    name: 'selfReview',
    component: SelfReviewView
  },
  {
    path: '/comic',
    name: 'comic',
    component: ComicView,
    children: [
      {
        path: '/comic/',
        name: 'comicList',
        component: comicList
      },
      {
        path: '/comic/comicDetail',
        name: 'comicDetail',
        component: comicDetail
      }
    ]
  },
  {
    path: '/Customer',
    name: 'Customer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: CustomerView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
