<template>
  <div class="ComicViewBox">
    <div class="comicOptionTitle">
      动漫乐园
    </div>
    <router-View></router-View>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped>
.comicOptionTitle {
  height: 40px;
  line-height: 40px;
  font-weight: bold;
  color: #333;
  font-size: 24px;
  border-bottom: 1px solid #ccc;
}
.comicOptionContent {
  font-size: 16px;
  color: #666;
}
.comicOptionTitle,
.comicOptionContent {
  text-align: left;
  width: 1200px;
  margin: auto;
  padding: 15px;
  line-height: 40px;
}
</style>
